import { TicketshopPage } from "components/TicketshopPage";
import React from "react";

export default function SomethingGoodPage() {
  return (
    <TicketshopPage
      scriptString={`<script src="https://web-cdn.fixr.co/scripts/fixr-shop-widget.v1.min.js?salesChannel=whitelabel-website" data-name-filter="somethin' good" data-fixr-shop-id="be31447a-9d7b-4108-bde6-c9cb9a5287e1" data-fixr-theme="light"></script>`}
      imageSrc="/logo/somethin-good-logo.png"
      backgroundSrc="/hero/somethin-good-hero.jpg"
      imageMaxWidth="322px"
      title="SOMETHIN' GOOD"
      description={
        <>
          <p>
            This has been a long time comin'. Head down the legendary HiFi Club
            stairs and enter a feel good world of Saturday night dancin' and
            fist pumpin'. A welcoming dancefloor full of grooves, friendly faces
            & impromptu dance moves powered by house, garage, afrobeats, hip
            hop, disco & world music. We'll be ravin' & misbehavin' every
            Saturday throughout the year! Expect a strict "zero tolerance on
            tossers" approach to ensure the best atmosphere & downright
            immaculate vibes from open through to close.
          </p>
          <p>Somethin' Good has arrived to Saturday nights in Leeds!</p>
        </>
      }
    />
  );
}
